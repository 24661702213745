import React, { useState, useEffect, useMemo, useRef } from "react";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Card from "./components/Card";
import HeroSingle from "./components/HeroSingle";
import Mascheroni from "./components/Mascheroni";
import AnnoNav from "./components/AnnoNav";
import { useLocation } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const Testo = ({ anno }) => {
  const containerRef = useRef(null);
  const rightRef = useRef(null);
  const leftRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const right = rightRef.current;
    const left = leftRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-5%",
      },
      {
        opacity: 1,
        duration: 2,
        y: "15%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.to(right, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(left, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div
      id="container_background"
      className="bg-[#1a1a1a] lg:h-[50dvh] mt-[50vh] md:mt-[80vh] lg:mt-[100vh]"
    >
      <div
        id="containertestosingle"
        className="bg-[#1a1a1a] roundedShadow flex flex-col lg:flex-row px-[10%] pb-[10%]"
        ref={containerRef}
      >
        <div className="flex flex-col items-center lg:items-start text-center lg:text-left ">
          <h1 className="font-bold Druk-Text text-[#FDF7E4] md:h-[200px] text-[40px] md:text-[60px] lg:text-[80px] leading-none text-center lg:text-left  ">
            MASCHERONE <span> BIRROCCO</span>
          </h1>
          <p
            className="lg:pr-10 my-8 pr-4 lg:pr-12 Haptik text-[#FDF7E4] text-center md:text-left  "
            ref={rightRef}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua.
          </p>
        </div>

        <div
          id="info_nft"
          className="flex lg:flex-col justify-between lg:gap-8 lg:justify-start "
          ref={leftRef}
        >
          <div id="anno" className="order-2 lg:order-1 self-start mb-auto">
            <p className="numero h-[200px] lg:text-[80px] lg:leading-none">
              2018
            </p>
          </div>
          <div id="localita" className="order-1 lg:order-2 ">
            <p id="place" className="nft-card-title mb-2">
              LOCALITA'
            </p>
            <p id="place-text" className="Haptik ">
              lorem ipsum
            </p>
          </div>
          <div id="collocazione" className="order-3 lg:order-3">
            <p id="place" className="nft-card-title mb-2">
              COLLOCAZIONE
            </p>
            <p id="place-text" className="Haptik ">
              lorem ipsum
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const style = {
  wrapper: `flex flex-col  items-center justify-center`,
  container: ``,
};
/* 
const { connectWallet, fetchAll, getMktBalance, getItemsSold } =
  require("./lib/ethers").init(window.ethereum); */
const Navigation = ({ links, isActive, updateActive }) => {
  return (
    <div className="d-flex flex-column">
      <button
        type="button"
        className="align-self-end btn btn-dark mt-3 mx-5"
        data-bs-toggle="modal"
        data-bs-target="#modal"
      >
        Upload+
      </button>
      <div className="d-flex justify-content-center mt-3">
        <ul className="nav">
          {links.map((link, index) => {
            const active = isActive === index ? "active" : "";
            return (
              <li
                key={link}
                className="nav-item"
                onClick={() => updateActive(index)}
              >
                <a
                  className={`nav-link ${active}`}
                  aria-current="page"
                  href="/#"
                >
                  {link}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const Pending = ({ isVisible }) =>
  isVisible ? (
    <p className="mt-4 text-center"> Fetching the NFTs ....</p>
  ) : null;

const Market = ({ isVisible, isActive, items }) => {
  if (isVisible && !items.length) {
    return <p className="mt-4 text-center">No items to show </p>;
  }
  return isVisible ? (
    <div className="container-fluid d-flex justify-content-center mt-5">
      <div className="d-flex justify-content-start flex-wrap text-center">
        {items.map((nft) => {
          return (
            <Card
              isActive={isActive}
              key={`${nft.tokenId}-${nft.tokenURI}`}
              {...nft}
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

export default function Pagina2018() {
  const location = useLocation();

  //make a number from the last 4 digits of location.pathname

  const anno = useMemo(() => {
    return parseInt(location.pathname.slice(-4));
  }, [location.pathname]);

  /* const links = ["Collezione Birrocco", "I miei NFT"];
  const [account, setAccount] = useState(null);
  const [items, setItems] = useState([]);
  const [isPending, loading] = useState(true);
  const [active, setActive] = useState(0);

  const updateActive = (index) => {
    setActive(index);
  };
  const getNFTs = async () => {
    const items = await fetchAll();
    setItems(items);
    loading(false);
  };

  const filtered = useMemo(() => {
    return active === 0
      ? items
      : items?.filter((item) => item.owner === account?.admin);
  }, [items, active]);

/*   useEffect(() => {
    connectWallet()
      .then(getMktBalance)
      .then(getItemsSold)
      .then(({ itemsSold, contractBalance, account }) => {
        setAccount(account);
        getNFTs();
      });
  }, []);
 */
  // tokenId = 0; */
  return (
    <>
      <div className={style.wrapper}>
        <HeroSingle
          image={
            "https://emerald-petite-bedbug-697.mypinata.cloud/ipfs/QmchBE8QC5fr2pdjpwS6iVKLo3RiTtBpxUAFP8yh3pPFYS"
          }
        />
        <Testo />
        <Mascheroni />
        <Card
          image={
            "https://emerald-petite-bedbug-697.mypinata.cloud/ipfs/QmchBE8QC5fr2pdjpwS6iVKLo3RiTtBpxUAFP8yh3pPFYS"
          }
          /*  isVisible={true} */
          tokenId={5}
          tokenURI={
            "https://emerald-petite-bedbug-697.mypinata.cloud/ipfs/QmchBE8QC5fr2pdjpwS6iVKLo3RiTtBpxUAFP8yh3pPFYS"
          }
          isListed={true}
        />
        <AnnoNav anno={anno} />
      </div>
    </>
  );
}
