import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import React, { useEffect, useRef } from "react";
import { Html, useProgress } from "@react-three/drei";

import { OrbitControls } from "@react-three/drei";
import Model from "./Model";
import Typewriter from "typewriter-effect";

gsap.registerPlugin(ScrollTrigger);

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}
const style = {
  wrapper: ``,
  container: ``,
  contentHeroWrapper: `flex h-[35dvh] sm:h-[100dvh] sm:pt-24 mt-24 mb-4 sm:mt-12 md:mt-32 lg:mt-40 border-t-[1px] border-solid border-white w-full`,
  copyContainer: `flex flex-col`,
  title: `text-[#FDF7E4] flex order-1;`,
  //description: `text-[#FDF7E4] container-[400px] text-2xl mt-[0.8rem] mb-[2.5rem]`,
  ctaContainer: `flex order-2 `,
  accentedButton: ` relative text-lg font-semibold px-12 py-4 bg-[#2181e2] rounded-lg mr-5 text-white hover:bg-[#42a0ff] cursor-pointer`,
  button: ` relative text-lg font-semibold px-12 py-4 bg-[#363840] rounded-lg mr-5 text-[#e4e8ea] hover:bg-[#4c505c] cursor-pointer`,
  cardContainer: `opacity-0 absolute left-[20%] sm:left-[35%] sm:top-[15%] h-[50vh] sm:h-[100vh] w-[320px] sm:w-[800px]  justify-center items-center overflow-visible`,
  infoContainer: `h-200 bg-[#313338] p-4 rounded-b-lg flex items-center text-white`,
  author: `flex flex-col justify-center ml-4`,

  infoIcon: `flex justify-end items-center flex-1 text-[#8a939b] text-3xl font-bold`,
};

const Hero = () => {
  const cardRef = useRef(null);

  useEffect(() => {
    const triggerElement = cardRef.current;
    gsap.fromTo(
      triggerElement,
      { autoAlpha: 1 },
      {
        autoAlpha: 0,
        ease: "power2.inOut",
        scrollTrigger: {
          trigger: triggerElement,
          start: "top top",
          end: "bottom top",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.contentHeroWrapper}>
          <div className={style.copyContainer}>
            <div className={style.title}>
              <div
                id="hero-title"
                className="text-[65px] sm:text-[80px] md:text-[125px] lg:text-[150px] xl:text-[220px] text-wrap max-w-sm mt-4 mx-12 md:mx-32 lg:mx-44 mb-11 sm:mb-0"
              >
                <h1
                  className="center__text glitch is-glitching sm:mb-[1rem] md:mb-[1.5rem] lg:mb-[2.5rem] xl:mb-[5rem]"
                  data-text="BIRROCCO"
                >
                  BIRROCCO
                </h1>

                <h1
                  id="hero-subtitle"
                  className="text-[35px] sm:text-[60px] md:text-[100px] lg:text-[120px] xl:text-[180px] absolute ml-10 sm:ml-20 md:ml-30 lg:ml-40 z-[1000] leading-none"
                >
                  <Typewriter
                    options={{
                      strings: ["NFT PROJECT", "NFT PROJECT"],
                      autoStart: true,
                      loop: true,
                      cursor: "",
                    }}
                  />
                </h1>
              </div>
            </div>

            {/*    <div className={style.ctaContainer}>
           <button className={style.accentedButton}>Explore</button>
              <button className={style.button}>Create</button>
            </div> */}
          </div>

          <div id="cardContainer" className={style.cardContainer} ref={cardRef}>
            <Canvas
              id="1"
              camera={{
                fov: 20,
                near: 20,

                position: [0, 0, -50],
              }}
              gl={{ antialias: true }}
            >
              <Suspense fallback={<Loader />}>
                <OrbitControls
                  enableRotate={false}
                  enableZoom={false}
                  autoRotate={false}
                  autoRotateSpeed={0.1}
                  maxPolarAngle={Math.PI / 2}
                  minPolarAngle={Math.PI / 2}
                  maxAzimuthAngle={-Math.PI / 2}
                  minAzimuthAngle={Math.PI / 1.5}
                />
                <Model id="1" />{" "}
              </Suspense>
            </Canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
