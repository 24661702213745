import React from "react";


function AnnoNav({image, anno}) {


const backgroundImage = image;




const annoNext= anno < 2023 ? anno  +1 : null
const annoPre= anno > 2013 ? anno -1 : null
 

  return (

<div className="">
    <div className="mt-24 flex flex-row items-center">
      
            

           
    <a href=  {annoPre}  >
         <svg className="mx-10" width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.292892 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM101 7L1 7V9L101 9V7Z" fill="#FDF7E4"/>
</svg>  
          </a>
         <p className="numero text-[45px] md:text-[70px] ">  {anno}
    

            </p>
            <a href= {annoNext}  >
      
            <svg className="mx-10" width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100.707 8.70711C101.098 8.31658 101.098 7.68342 100.707 7.29289L94.3431 0.928932C93.9526 0.538408 93.3195 0.538408 92.9289 0.928932C92.5384 1.31946 92.5384 1.95262 92.9289 2.34315L98.5858 8L92.9289 13.6569C92.5384 14.0474 92.5384 14.6805 92.9289 15.0711C93.3195 15.4616 93.9526 15.4616 94.3431 15.0711L100.707 8.70711ZM0 9H100V7H0L0 9Z" fill="#FDF7E4"/>
</svg>


        {/*     <p className="numero text-[30px] md:text-[70px] m-10"> &#x2192;</p> */}
          </a>
       
    </div>
      </div>
  );
}

export default AnnoNav;
 

