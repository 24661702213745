import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Faqlib from "react-faq-component";

gsap.registerPlugin(ScrollTrigger);

const data = {
  title: "",
  rows: [
    {
      title: "Come posso acquistare gli NFT di Birrocco?",
      content: `Per acquistare un NFT del Mascherone del Birrocco è necessario possedere un portafoglio di criptovalute (Metamask, Coinbase Wallet, Trust Wallet, ecc.) con un saldo di almeno 0,4 ETH.`,
    },
    {
      title:
        "Quali sono i requisiti per acquistare un Birrocco Mascherone NFT?",
      content: `Ci sono quattro requisiti per acquistare un Mascherone del Birrocco NFT:

        È necessario possedere un portafoglio di criptovalute;
        Hai bisogno di 0,3 ETH sul tuo portafoglio di criptovalute per l'acquisto;
        Hai bisogno di 0,1 ETH sul tuo portafoglio di criptovalute per le spese di gas.`,
    },
    {
      title:
        "Quanti Mascheroni del Birrocco NFT sono disponibili per l'acquisto?",
      content: `Per questo primo drop saranno disponibili per l'acquisto un totale di 8 Mascheroni.`,
    },
    {
      title: "Come posso visualizzare il mio Mascherone del Birrocco NFT?",
      content: `Visita la sezione "I miei NFT" dopo aver acquistato un Mascherone del Birrocco su birrocconft.com o importa il tuo NFT nel tuo portafoglio crittografico.`,
    },
  ],
};

const styles = {
  bgColor: "#1a1a1a",
  titleTextColor: "#FDF7E4",
  rowTitleColor: "#FDF7E4",
  rowContentColor: "#FDF7E4",
  arrowColor: "#FDF7E4",
  rowTitleTextSize: "large",

  rowContentTextSize: "medium",
  rowTitlePaddingBottom: "50px",
  rowTitlePaddingTop: "50px",
  rowContentPaddingTop: "30px",
  rowContentPaddingBottom: "30px",
};

const config = {
  animate: true,
  arrowIcon: "+",
  tabFocus: true,
  // tabFocus: true
};

const Faq = () => {
  const containerRef = useRef(null);
  const containerTestoRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const containerTesto = containerTestoRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom center",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      containerTesto,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 2,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex-nowrap justify-around px-12 md:px-32 lg:px-44 mt-20">
      {" "}
      <div className="flex justify-around">
        <h1
          id="sezione-collezione"
          className="text-[35px] md:text-[60px]  lg:text-[140px] Druk-Text font-bold  sm:tracking-wider text-[#FDF7E4]"
          ref={containerRef}
        >
          FAQ
        </h1>
      </div>{" "}
      <div className=" justify-center  mt-5">
        <div>
          <Faqlib data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
