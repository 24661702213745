import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";

// The spinner component
const Loader = () => (
  <div className="Loader-container">
    <div className="Loader">Loading...</div>
  </div>
);

function Model() {
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    "../node_modules/three/examples/js/libs/draco/gltf/"
  );
  const gltfLoader = new GLTFLoader();
  gltfLoader.setDRACOLoader(dracoLoader);

  const { scene } = useLoader(GLTFLoader, "./mascherone3.glb", (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.decoderPath = "/draco/";
    loader.setDRACOLoader(dracoLoader);
    setIsLoading(false); // Set loading to false here
  });

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    if (ref.current) {
      ref.current.rotation.set(
        0.1 + Math.cos(t / 80.5) / 20,
        Math.sin(t / 40) / 4,
        0.3 - (1 + Math.sin(t / 40)) / 8
      );
      ref.current.position.y = (1 + Math.sin(t / 1)) / 2;
    }
  });
  useGLTF.preload("./20.glb");
  return (
    <group ref={ref}>
      <ambientLight intensity={1} />
      <directionalLight intensity={10} position={[12, 10, -50]} />
      <pointLight intensity={5} position={[-10, -20, -20]} />{" "}
      <pointLight
        castShadow
        angle={1}
        intensity={5000}
        position={[-20, 5, -20]}
      />
      <pointLight castShadow angle={1} intensity={12} position={[0, 22, -20]} />
      {scene && (
        <primitive
          object={scene}
          scale={0.002}
          position={[0, 0, 0]}
          rotation-x={-Math.PI / 7}
          rotation-y={-Math.PI / 15}
        />
      )}
    </group>
  );
}
export default Model;
