import BenefitItem from "./BenefitItem.js";

import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Benefit = () => {
  const containerRef = useRef(null);
  const containerTestoRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const containerTesto = containerTestoRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      containerTesto,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 2,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex-nowrap sm:text-center mx-12 mt-5 md:mt-18 lg:mt-24">
      <div className="flex justify-around">
        <h1
          id="sezione-collezione"
          className=" text-[35px] md:text-[50px]  lg:text-[140px] Druk-Text font-bold  sm:tracking-wider text-[#FDF7E4]"
          ref={containerRef}
        >
          BENEFITS
        </h1>
      </div>{" "}
      <div className="">
        <div className="flex justify-center mt-5 ">
          <p
            className="font-medium Druk Text Wide Trial  text-[#FDF7E4]   mt-[0.8rem] mb-1"
            ref={containerTestoRef}
          ></p>
        </div>
      </div>
      <div
        id="card-container"
        className="grid grid-cols-2 lg:grid-cols-5 gap-x-5 md:gap-x-[10%] md:mx-32 lg:mx-44
    "
      >
        <BenefitItem
          nome_vantaggio={"Accesso Esclusivo alla Community Virtuale"}
          className=""
          numero={"1"}
          description="I possessori del Mascherone NFT otterranno un accesso VIP alla community virtuale dedicata al progetto, dove potranno interagire, condividere esperienze e partecipare a eventi esclusivi."
        />

        <div id="pari" className="mt-20  ">
          {" "}
          <BenefitItem
            nome_vantaggio={"Co-Creazione Artistica"}
            numero={"2"}
            description="Gli acquirenti avranno l'opportunità unica di partecipare attivamente alla co-creazione del progetto, influenzando le future iterazioni e contribuendo a definire nuovi sviluppi attraverso sessioni di sondaggi riservati."
          />{" "}
        </div>

        <BenefitItem
          nome_vantaggio={"Accesso Anticipato a Collezioni Esclusive di NFT"}
          className=""
          numero={"3"}
          description="I possessori di Mascheroni NFT avranno il privilegio di accedere in anteprima alle nuove collezioni digitali, garantendo loro la possibilità di acquistare e collezionare pezzi esclusivi prima della pubblicazione ufficiale."
        />

        <div id="pari" className=" mt-20 ">
          <BenefitItem
            nome_vantaggio={"Offerte Speciali e Vendite Private"}
            numero={"4"}
            description="Beneficeranno di accesso prioritario e di un'esperienza esclusiva durante il Birrocco - Festival della Birra Artigianale: una degustazione di 8 birre artigianali. Un viaggio sensoriale unico per arricchire la loro partecipazione all'evento."
          />
        </div>
        <BenefitItem
          nome_vantaggio={"Sconto Esclusivo a Lungo Termine"}
          className=""
          numero={"5"}
          description="Oltre ai vantaggi immediati, i possessori di Mascheroni NFT riceveranno uno sconto del 10%, applicabile su ulteriori pezzi per un periodo di 12 mesi dall'acquisto, consentendo di espandere la collezione di Mascheroni NFT."
        />
      </div>
    </div>
  );
};

export default Benefit;
