import Cards from "./Cards.js"; // assuming you have a separate Card component
import image2013 from "../assets/2013.webp";
import image2015 from "../assets/2015.webp";
import image2016 from "../assets/2016.webp";
import image2017 from "../assets/2017.webp";
import image2018 from "../assets/2018.webp";
import image2019 from "../assets/2019.webp";
import image2022 from "../assets/2022.webp";
import image2023 from "../assets/2023.webp";
import React from "react";

import { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

gsap.registerPlugin(ScrollTrigger);

function Collezione() {
  const [pagina, setPagina] = useState("");
  const navigate = useNavigate();

  function handleClickCardNFT(e) {
    const value = e.target.getAttribute("value");
    setPagina(value);
    navigate(value);
  }

  const containerRef = useRef(null);
  const containerTestoRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const containerTesto = containerTestoRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      containerTesto,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        delay: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex-nowrap justify-around text-left sm:text-center ">
      <div className="flex justify-around">
        <h1
          id="sezione-collezione"
          className="text-[35px] md:text-[50px]  lg:text-[120px] xl:text-[140px] Druk-Text font-bold  sm:tracking-wider text-[#FDF7E4]"
          ref={containerRef}
        >
          COLLECTION
        </h1>
      </div>{" "}
      <div className="flex justify-center">
        <div className="flex justify-center w-full lg:w-3/4  ">
          <p
            className="font-medium Druk-Text  text-[#FDF7E4] container-[400px] text-[15px] sm:text-[15px]  mb-1 w-full  mt-10 md:mt-18 lg:mt-24 md:mx-44 m-12"
            ref={containerTestoRef}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>
      <div
        id="row-container"
        className="flex flex-row lg:flex-col justify-center m-12 md:mx-32 lg:mx-44 gap-x-5"
      >
        <div
          id="card-container"
          className="flex-col lg:flex lg:flex-row mb-0 sm:mb-10  mt-32 justify-between"
        >
          <div className="">
            <Cards
              image={image2013}
              avatar={image2013}
              handleClickCardNFT={handleClickCardNFT}
              name="Birrocco"
              description="By Ideology wiffth &#128154;"
              IdNFT={2013}
            />{" "}
          </div>
          <div className="">
            <Cards
              image={image2015}
              avatar={image2015}
              name="Author 3"
              description="Description 3"
              handleClickCardNFT={handleClickCardNFT}
              IdNFT={2015}
            />{" "}
          </div>
          <div className="">
            <Cards
              image={image2016}
              avatar={image2016}
              name="Author 2"
              description="Description 2"
              handleClickCardNFT={handleClickCardNFT}
              IdNFT={2016}
            />
          </div>
          <div className=" ">
            <Cards
              image={image2017}
              avatar={image2017}
              name="Author 2"
              description="Description 2"
              handleClickCardNFT={handleClickCardNFT}
              IdNFT={2017}
            />
          </div>
          {/* add other Card components with appropriate images */}
        </div>
        <div id="row2">
          <div
            id="card-container"
            className="flex-col lg:flex lg:flex-row mb-0 justify-between"
          >
            <div className=" ">
              <Cards
                image={image2018}
                avatar={image2018}
                handleClickCardNFT={handleClickCardNFT}
                name="Birrocco"
                description="By Ideology wiffth &#128154;"
                IdNFT={2018}
              />{" "}
            </div>
            <div className=" ">
              <Cards
                image={image2019}
                avatar={image2019}
                name="Author 3"
                description="Description 3"
                handleClickCardNFT={handleClickCardNFT}
                IdNFT={2019}
              />{" "}
            </div>
            <div className=" ">
              <Cards
                image={image2022}
                avatar={image2022}
                name="Author 3"
                description="Description 3"
                handleClickCardNFT={handleClickCardNFT}
                IdNFT={2022}
              />{" "}
            </div>

            <div className=" ">
              <Cards
                image={image2023}
                avatar={image2023}
                name="Author 2"
                description="Description 2"
                handleClickCardNFT={handleClickCardNFT}
                IdNFT={2023}
              />
            </div>
            {/* add other Card components with appropriate images */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collezione;
