import React from "react";

function BenefitItem({ description, image, numero, nome_vantaggio }) {
  return (
    <div className="flex flex-col justify-start items-start ">
      <div className=" w-32 h-32 sm:w-40 sm:h-40 rounded-full border-2 border-slate-100 flex justify-center items-center text-left">
        <p className=" numero text-5xl sm:text-7xl text-[#1A1A1A] ">{numero}</p>
      </div>
      <div className="flex flex-col justify-start items-start">
        <div
          id="vantaggio-benefit"
          className="vantaggio-benefit text-center my-2 sm:my-4 text-[15px]  sm:text-[20px]  justify-start items-start"
        >
          {nome_vantaggio}
        </div>

        <div
          id="vantaggio-descrizione-id"
          className="sm:min-h-[220px] min-h-[120px] justify-start items-start text-left"
        >
          <p className="vantaggio-descrizione text-center Haptik text-[10px] sm:text-[15px]">
            {description}
          </p>
        </div>
      </div>
      {/*  avatar="https://example.com/path/to/avatar2.jpg" name="Author 3"
      description="Description 3" */}
    </div>
  );
}

export default BenefitItem;
