import React, { useRef, useEffect } from "react";
import VanillaTilt from "vanilla-tilt";

import image2013 from "../assets/2013.webp";
import image2015 from "../assets/2015.webp";
import image2016 from "../assets/2016.webp";
import image2017 from "../assets/2017.webp";
import image2018 from "../assets/2018.webp";

function Mascheroni({ image }) {
  const tiltNodes = useRef([]);

  // Add all tilting images to tiltNodes array
  const addToRefs = (el) => {
    if (el && !tiltNodes.current.includes(el)) {
      tiltNodes.current.push(el);
    }
  };

  useEffect(() => {
    if (tiltNodes.current) {
      tiltNodes.current.forEach((el) => {
        VanillaTilt.init(el, {
          transition: true,
          reverse: true,
          max: 15,
          speed: 500,
          glare: true,
          "max-glare": 1,
          easing: "cubic-bezier(.03,.98,.52,.99)",
        });
      });
    }
    // clean up phase
    return () => {
      tiltNodes.current.forEach((el) => {
        el.vanillaTilt.destroy();
      });
    };
  }, []); // Run only once

  return (
    <div className="rounded-full m-12 sm:m-22 md:m-32 grid grid-cols-2 xl:grid-cols-4 gap-14 sm:gap-28 items-end justify-items-center">
      <img
        ref={addToRefs}
        src={image2013}
        alt="Image 1"
        style={{
          width: "245px",
          height: "334px",
          objectFit: "cover",
          borderRadius: "5px",
        }}
      />
      <img
        ref={addToRefs}
        src={image2015}
        alt="Image 2"
        style={{
          width: "183px",
          height: "170px",
          objectFit: "cover",
          borderRadius: "5px",
        }}
      />
      <img
        ref={addToRefs}
        src={image2017}
        alt="Image 3"
        style={{
          width: "305px",
          height: "264px",
          objectFit: "cover",
          borderRadius: "5px",
        }}
      />
      <img
        ref={addToRefs}
        src={image2018}
        alt="Image 4"
        style={{
          width: "216px",
          height: "332px",
          objectFit: "cover",
          borderRadius: "5px",
        }}
      />
    </div>
  );
}

export default Mascheroni;
