import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Testo = () => {
  const containerRef = useRef(null);
  const rightRef = useRef(null);
  const leftRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const right = rightRef.current;
    const left = leftRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.to(right, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(left, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div
      className="container-flex flex-column md:flex-row mb-10 mx-12 md:mx-32  lg:mx-44  md:mt-42  lg:mb-44 "
      ref={containerRef}
    >
      <div className="">
        <div className="flex justify-center ">
          <p className="font-bold Druk-Text w-full text-[#FDF7E4] container-[400px] text-[20px] sm:text-5xl mt-[0.8rem] mb-1">
            Il Mascherone e' simbolo del Birrocco. Inizialmente elemento
            artistico radicato nella tradizione barocca, si trasforma in un
            elemento dinamico quando si proietta nel mondo digitale, dove
            passato e futuro si fondono con stile, creativita' e innovazione.
          </p>
        </div>
        <div className="flex  flex-wrap">
          <p
            className="w-full md:w-1/2 pr-4 pt-8 Druk-Text text-[#FDF7E4] text-[15px] sm:text-[15px] font-normal "
            ref={rightRef}
          >
            L'oggetto mascherone, intriso di significati culturali e storici,
            assume una nuova vita attraverso la dimensione "metafisica" che gli
            conferisce un'interessante dualità tra il tangibile e l'immateriale.
            Si libera dalle sue origini fisiche per ritrovarsi in una realtà
            digitale, dove le sue forme si ripetono e si distribuiscono in un
            intricato intreccio di elementi. Questa trasformazione apre le porte
            a una dimensione che va oltre la sua rappresentazione materiale.
          </p>
          <p
            className="w-full md:w-1/2 md:pl-4 pt-8 Druk-Text text-[#FDF7E4] text-[15px] sm:text-[15px] font-normal"
            ref={leftRef}
          >
            Questo consente al Mascherone di esplorare nuovi orizzonti
            metaforici e simbolici.In questo scenario "metafisico", il
            Mascherone del Birrocco diventa una presenza poliedrica, le cui
            manifestazioni digitali si richiamano l'una con l'altra, ricreando
            la sua forma iconica in un movimento visivo, dove la sua identità si
            fonde con la creatività digitale, creando un connubio affascinante
            tra il passato e il futuro.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testo;
