import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Pagina2013 from "./Pagina2013";
import Pagina2015 from "./Pagina2015";
import Pagina2016 from "./Pagina2016";
import Pagina2017 from "./Pagina2017";
import Pagina2018 from "./Pagina2018";
import Pagina2019 from "./Pagina2019";
import Pagina2022 from "./Pagina2022";
import Pagina2023 from "./Pagina2023";

import Navbar from "./components/Navbar";

import Footer from "./components/Footer";

import Home from "./Home";

import Cursor from "./components/Cursor";

/* const { connectWallet, fetchAll, getMktBalance, getItemsSold } =
  require("./lib/ethers").init(window.ethereum); */
/* const Navigation = ({ links, isActive, updateActive }) => {
  return (
    <div className="d-flex flex-column">
      <button
        type="button"
        className="align-self-end btn btn-dark mt-3 mx-5"
        data-bs-toggle="modal"
        data-bs-target="#modal"
      >
        Upload+
      </button>
      <div className="d-flex justify-content-center mt-3">
        <ul className="nav">
          {links.map((link, index) => {
            const active = isActive === index ? "active" : "";
            return (
              <li
                key={link}
                className="nav-item"
                onClick={() => updateActive(index)}
              >
                <a
                  className={`nav-link ${active}`}
                  aria-current="page"
                  href="/#"
                >
                  {link}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}; */

const Pending = ({ isVisible }) =>
  isVisible ? (
    <p className="mt-4 text-center"> Fetching the NFTs ....</p>
  ) : null;

export default function App() {
  const links = ["Collezione Birrocco", "I miei NFT"];
  const [account, setAccount] = useState(null);
  const [mktBalance, setBalance] = useState(null);
  const [itemsSold, setItemsSold] = useState(null);
  const [items, setItems] = useState([]);
  const [isPending, loading] = useState(true);
  const [active, setActive] = useState(0);

  /*  const updateActive = (index) => {
    setActive(index);
  }; */
  /*  const getNFTs = async () => {
    const items = await fetchAll();
    setItems(items);
    loading(false);
  }; */

  const filtered = useMemo(() => {
    return active === 0
      ? items
      : items?.filter((item) => item.owner === account?.admin);
  }, [items, active, account]);

  /* useEffect(() => {
    connectWallet()
      .then(getMktBalance)
      .then(getItemsSold)
      .then(({ itemsSold, contractBalance, account }) => {
        setAccount(account);
        setBalance(contractBalance);
        setItemsSold(itemsSold);
        getNFTs();
      });
  }, []); */

  return (
    <Router>
      <Cursor />
      <Navbar />
      {/*  account={account} balance={mktBalance} itemsSold={itemsSold} */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/2013"
          element={<Pagina2013 />}
          preventScrollReset={false}
        />
        <Route path="/2015" element={<Pagina2015 />} />
        <Route path="/2016" element={<Pagina2016 />} />
        <Route path="/2017" element={<Pagina2017 />} />
        <Route path="/2018" element={<Pagina2018 />} />
        <Route path="/2019" element={<Pagina2019 />} />
        <Route path="/2022" element={<Pagina2022 />} />
        <Route path="/2023" element={<Pagina2023 />} />
      </Routes>

      <Footer />
    </Router>
  );
}
